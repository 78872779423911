import { HttpMutationFormResponseTransformer } from 'packages/http-client/react-forms';
import { FORM_ERROR } from 'final-form';

export interface CommonApiFormResponseValidationError {
  field: string;
  error: string;
}

export interface CommonApiFormResponseData {
  error?: string;
  validation_errors?: CommonApiFormResponseValidationError[];
}

const isString = (v: any): v is string => typeof v === 'string';

const isCommonApiFormResponseValidationError = (
  data: any
): data is CommonApiFormResponseValidationError =>
  data && isString(data.error) && isString(data.field);

const isCommonApiFormResponseData = (
  data: any
): data is CommonApiFormResponseData =>
  !!data &&
  !!(
    isString(data.error) ||
    (Array.isArray(data.validation_errors) &&
      data.validation_errors.every(isCommonApiFormResponseValidationError))
  );

export const commonNetspendApiFormResponseTransformer: HttpMutationFormResponseTransformer = (
  response,
  body,
  parentTransformer
) => {
  // We only support 400s right now, and they must be in the proper shape
  if (response.status === 400 && isCommonApiFormResponseData(body)) {
    const formErrors: Record<string, string> = {};
    if (body.error) {
      // Copy over a global error if we found one
      formErrors[FORM_ERROR] = body.error;
    }
    if (body.validation_errors) {
      body.validation_errors.forEach(({ field, error }) => {
        // We do not support multiple errors on a single field right now, so
        // copy over the last one found
        formErrors[field] = error;
      });
    }

    if (Object.keys(formErrors).length > 0) {
      // Only return the object if we have found errors to display
      // otherwise we will let the assert take care of it
      return formErrors;
    }
  }

  return parentTransformer();
};
